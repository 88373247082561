<template>
  <section class="footer__main-routes">
    <h2 class="sub-heading sub-heading--white">
      {{ $t("heading.services") }}
    </h2>
    <b-nav vertical>
      <b-nav-item
        v-for="(service, index) in services"
        :key="index"
        :to="`/services/${service.id}/service-details`"
        >{{ service.name }}</b-nav-item
      >
    </b-nav>
  </section>
</template>

<script>
export default {
  name: "ServicesLits",
  computed: {
    services() {
      return this.$store.getters.GlobalServices;
    }
  }
};
</script>

<style lang="scss" scoped></style>
